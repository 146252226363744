import * as React from "react";

import Header from "@shared/header";
import { Container } from "@util/standard";

const paragraphStyles = {
  marginBottom: 48,
};

const NotFoundPage = () => {
  return (
    <div>
      <Header isSolidHeader />
      <title>Not found</title>
      <Container flexDirection="column" width="80%" padding="200px 0" margin="auto">
        <h1>Page not found</h1>
        <p style={paragraphStyles}>
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn’t find what you were looking for.
          <br />
        </p>
      </Container>
    </div>
  );
};

export default NotFoundPage;
